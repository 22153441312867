#loginClient {
  .banner {
    padding: 127px 0;
    background-color: #f6f9fc;

    .border {
      border: 3px solid black;
      margin: 80px auto;
      border-radius: 20px;
      padding: 1rem;
      max-width: 900px;
      position: relative;

      .form_wrapper {
        background-color: $white;
        padding: 40px;
        border-radius: 30px;
        display: flex;

        .form_logo {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          background-color: #ebecf4;
          width: 300px;
          height: 300px;
          border-radius: 25px;

          img {
            margin: 0 auto;
            background: url($logo3);
            padding: 153px 239px 0 0;
            background-size: 239px 153px;
            background-repeat: no-repeat;
          }
        }

        .form_box {
          width: 60%;
          margin-left: auto;
          padding: 0 35px 15px;

          /deep/ .el-button {
            width: 100%;
            background-color: $primary-purple;
          }

          .title {
            margin-top: 10px;
            font-size: 24px;
            font-family: 'GTWalsheimPro-Bold';
            text-align: center;
          }

          /deep/ .el-form {
            margin-top: 20px;
            .verification_wrapper {
              display: flex;
              justify-content: center;
              margin-bottom: 10px;
            }
            .verfifyMessage {
              display: flex;
              justify-content: center;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 15px;
            }

            div.slide-verify-slider {
              border: none;
            }
            div.slide-verify-slider:hover {
              border: none;
            }
            div.slide-verify-slider-mask {
              height: 40px;
            }
            div.slide-verify-slider-mask-item {
              background: $primary-purple;
              height: 40px;
            }
            .slide-verify-slider-mask-item-icon {
              background-position: 0 -13px;
            }

            span.slide-verify-slider-text {
              font-size: 12px;
              font-weight: bold;
              @include rtl-sass-prop(padding-left, padding-right, 35px);
            }

            .login_btn {
              text-align: center;
              margin-bottom: 24px;
            }

            .forgotPass {
              margin-bottom: 15px;
              text-align: center;
              font-weight: 600;
              line-height: 1;
              text-align: center;
              text-decoration: underline;
              a {
                color: black;
              }
            }
          }
        }
      }
    }

    .border::before {
      height: 5px;
      right: 18px;
      bottom: -4px;
      width: 100px;
      content: '';
      display: block;
      background: #f6f9fc;
      position: absolute;
    }

    .border::after {
      width: 5px;
      height: calc(100% - 300px);
      top: 250px;
      left: -4px;
      content: '';
      display: block;
      background: #f6f9fc;
      position: absolute;
    }
  }
}

@media (max-width: 768px) {
  #loginClient .banner .border {
    .form_wrapper {
      padding: 20px;

      .form_logo {
        display: none;
      }

      .form_box {
        margin: auto;
        padding: 0;
      }
    }
  }
}
